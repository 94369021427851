const toString = (input) => {
    return `${input}`;
};

export const sanitize = {
    toString,
};

const isArray = (input) => Array.isArray(input);

const isArrayBuffer = (input) => input instanceof ArrayBuffer;

const isBuffer = (input) => Buffer.isBuffer(input);

const isBlob = (input) => input instanceof Blob;

const isDate = (input) => input instanceof Date && typeof input.getMonth === 'function';

const isEmptyObject = (input) => {
    return input.constructor === Object && Object.keys(input).length === 0;
};

const isObject = (input) => {
    return input.constructor === Object;
};

const isString = (input) => typeof input === 'string' || input instanceof String;

const isStringEntered = (input) => {
    if (!isValue(input)) return false;
    if (!isString(input)) return false;
    if (input.length === 0) return false;
};

const isValidDatePattern = (dateString) =>
    isString(dateString) && dateString.match && !!dateString.match(/^\d{4}-\d{2}-\d{2}$/);

const isValidEmailAddress = (emailString) =>
    isString(emailString) &&
    emailString.match &&
    !!emailString.match(
        /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    );

const isValidISODatePattern = (ISODateString) =>
    isString(ISODateString) &&
    ISODateString.match &&
    !!ISODateString.match(
        /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
    );

const isValidMonthPattern = (monthString) =>
    isString(monthString) && monthString.match && !!monthString.match(/^\d{4}-\d{2}$/);

const isValue = (input) => !(input === undefined || input === null);

const isNumber = (input) => typeof input === 'number';

export const validate = {
    isArray,
    isArrayBuffer,
    isBlob,
    isBuffer,
    isDate,
    isEmptyObject,
    isObject,
    isString,
    isNumber,
    isStringEntered,
    isValidDatePattern,
    isValidEmailAddress,
    isValidISODatePattern,
    isValidMonthPattern,
    isValue,
};

export default {
    validate,
    sanitize,
};
